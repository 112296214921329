













































































import { Component, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { createCart, getPricesAddable } from '@/api/shop'
import { orderBy } from 'lodash'
import { isOldSubscription } from '@/utils/rules'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'CreditsSms',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    loading = false
    pricesAddable: any[] = []

    get user () {
        return UserModule.user
    }

    get shopUser () {
        return UserModule.shopUser
    }

    get smsAddable () {
        return orderBy(
            this.pricesAddable.filter(el => el.metadata?.credits && el.product.metadata?.creditType === 'sms'),
            'value',
            'asc',
        )
    }

    get isOldSubscription () {
        return isOldSubscription(this.user.configuration.rules)
    }

    public async beforeMount () {
        this.getPricesAddable()
    }

    private async getPricesAddable () {
        this.loading = true
        try {
            const resp = await getPricesAddable()
            this.pricesAddable = resp.data
            this.loading = false
        } catch (e) {
            console.log(e)
            this.pricesAddable = []
            this.loading = false
        }
    }

    private async createCart (priceId: string) {
        try {
            const resp = await createCart({
                items: [{
                    priceId,
                    quantity: 1,
                }],
            })
            this.trackCartCreatedMixpanelEvent(resp.data, 'PricePageSms')
            this.$router.push({
                name: 'cart',
            })
        } catch (e) {
            console.log(e)
        }
    }

    private trackCartCreatedMixpanelEvent (cart: any, sourceName: string) {
        if (UserModule.Mixpanel) {
            UserModule.Mixpanel.track(
                'CartCreated',
                {
                    distinct_id: this.user._id,
                    CartId: cart._id,
                    Trial: false,
                    HasSubscription: !!(this.shopUser && this.shopUser.subscription),
                    Interval: 'una_tantum',
                    Source: sourceName,
                },
            )
        }
    }
}
